#hero {
  max-width: 1600px;
  height: 600px;
  position: relative;
  margin: auto;
  background-image: url("../../images/girlOnRock.jpg");
  background-size: "cover";
  background-attachment: "fixed";
  background-position: "80%";
  background-repeat: no-repeat;
}

#heroText {
  text-align: left;
  position: absolute;
  font-size: x-large;
  top: 40px;
  left: 30px;
  transition: all ease-in-out 0.3s;
}

#header {
  margin: 5px;
  font-family: var(--fancyFont);
  font-size: 2em;
}

#subtext {
  margin: 5px;
  font-family: var(--subHeaderFont);
  font-weight: bold;
}

@media screen and (min-height: 500px) {
  #hero {
    height: 900px;
    background-position: 68%;
  }
}
@media screen and (min-width: 500px) {
  #hero {
    background-position: 68%;
  }
}
@media screen and (min-width: 700px) {
  #heroText {
    font-size: x-large;
    top: 100px;
    left: 80px;
  }
  #heroText #header {
    font-size: 2.5em;
  }
}
@media screen and (min-width: 900px) {
  #heroText {
    font-size: xx-large;
    top: 100px;
    left: 120px;
  }
  #heroText #header {
    font-size: 3em;
  }
}

@media screen and (min-width: 2000px) {
  #hero {
    max-width: 1800px;
  }
}

@media screen and (min-height: 1400px) {
  #hero {
    height: 1100px;
  }
}
