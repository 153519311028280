#formContainer input {
  width: 170px;
  height: 20px;
  padding: 2px;
}

.formContainer input {
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  border-radius: 2px;
}

/* #firstName {
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  border-radius: 2px;
} */

#formContainer input:hover {
  background-color: var(--formInputHover);
}

/* #firstName:hover {
  background-color: aliceblue;
} */

#formContainer input:focus {
  background-color: var(--formInputHover);
  border: none;
  border: 1px solid var(--formInputBorder);
}

/* #firstName:focus {
  background-color: aliceblue;
  border: none;
  border: 1px solid rgb(71, 169, 255);
} */

#formContainer input:focus-visible {
  outline: none;
}

/* #firstName:focus-visible {
  outline: none;
} */

.label {
  color: darkslategray;
  position: absolute;
  padding-left: 5px;
  width: fit-content;
  left: 0px;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
/* #firstNameLabel {
  color: darkslategray;
  position: absolute;
  padding-left: 5px;
  width: fit-content;
  left: 0px;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
} */

#firstInputContainer {
  position: relative;
  max-width: fit-content;
  margin: auto;
  margin-top: 30px;
}

#firstName:focus + #firstNameLabel {
  top: var(--smallFormLabelDistance);
}
#lastName:focus + #lastNameLabel {
  top: var(--smallFormLabelDistance);
}
#email:focus + #emailLabel {
  top: var(--smallFormLabelDistance);
}
#phone:focus + #phoneLabel {
  top: var(--smallFormLabelDistance);
}
#firstName:autofill + #firstNameLabel {
  top: var(--smallFormLabelDistance);
}
#lastName:autofill + #lastNameLabel {
  top: var(--smallFormLabelDistance);
}
#email:autofill + #emailLabel {
  top: var(--smallFormLabelDistance);
}
#phone:autofill + #phoneLabel {
  top: var(--smallFormLabelDistance);
}
#firstName:-webkit-autofill + #firstNameLabel {
  top: var(--smallFormLabelDistance);
}
#lastName:-webkit-autofill + #lastNameLabel {
  top: var(--smallFormLabelDistance);
}
#email:-webkit-autofill + #emailLabel {
  top: var(--smallFormLabelDistance);
}
#phone:-webkit-autofill + #phoneLabel {
  top: var(--smallFormLabelDistance);
}

.activeInput {
  border: none;
  border: 1px solid var(--formInputActiveBorder);
}

.inactiveInput {
  border: none;
  border: 1px solid darkslategray;
}

.activeLabel {
  top: -23px;
}

.inactiveLabel {
  top: 2px;
}
/* .activeFirstNameLabel {
  top: -23px;
}

.inactiveFirstNameLabel {
  top: 2px;
} */
.inputContainer {
  position: relative;
  max-width: fit-content;
  margin: auto;
  margin-top: 30px;
}

/* #password {
  border: none;
  border: 1px solid black;
  border-radius: 0px;
}

#password:focus {
  border: 2px solid black;
}

#password:focus-visible {
  outline: none;
}

#eyeIconContainer {
  position: absolute;
  top: 6px;
  right: 24px;
}

#eyeIcon {
  position: inherit;
} */

#profession {
  padding: 4px;
}

#resumeLabel {
  background-color: white;
  padding: 3px;
  border-radius: 2px;
  position: absolute;
  top: -29px;
  left: 0px;
  color: darkslategray;
  border: solid 1px gray;
  min-width: fit-content;
}

.resumeInputContainer {
  margin-top: 50px;
}

.submit-button {
  color: black;
  font-size: medium;
  border: none;
  padding: 10px;
  border-radius: 15px;
  margin-top: 20px;
  background-color: var(--formSubmitButton);
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  cursor: pointer;
  caret-color: transparent;
}

.disabled-submit-button {
  color: white;
  font-size: medium;
  border: none;
  padding: 10px;
  border-radius: 15px;
  margin-top: 20px;
  background-color: #6b6869;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.submit-button:hover {
  background-color: #d881a6;
  box-shadow: 0px 1px 3px #eea0c2;
}
