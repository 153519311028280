/* #applyContainer {
  display: flex;
  flex-direction: column;
} */

#applyHeader {
  font-size: 2.5em;
  font-family: var(--fancyFont);
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
}

#whiteStrip {
  background-color: white;
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0px 0px 3px gray;
  margin: auto;
  max-width: 700px;
}

#greenStrip {
  background-color: #aff1d5;
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0px 0px 3px gray;
  background-image: url("../../images/natureGuy.jpg");
  background-size: cover;
  background-position: center;
}

#formCard {
  background-color: rgba(255, 255, 255, 0.795);
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0px 0px 3px gray;
}

#formGraphic {
  display: none;
}

@media screen and (min-width: 950px) {
  #applyHeader {
    font-size: 3.5em;
  }

  #greenStrip {
    background-color: var(--green);
  }

  #applyFlex {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  #formGraphic {
    display: block;
    max-width: calc(100vw - 40vw);
    border-radius: 15px;
  }

  #formCard {
    min-width: 600px;
  }
}
@media screen and (min-width: 1200px) {
  #applyContainer {
    margin-top: calc(100vh - 90vh);
    flex-direction: row;
    justify-content: center;
  }
}
